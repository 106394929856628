<template>
  <div class="expande-horizontal px-3">
    <div
      style="background: #e2e2e2; border: 1px solid #f2f2f2; border-radius: 3px;"
      class="fonte expande-horizontal pt-6 pb-6 centraliza column"
    >
      <div
        style="min-height: 20vh;"
        class="expande-horizontal pa-6 pb-2 centraliza"
      >
        <v-flex xs8 md2>
          <LottieAnimation
            ref="anim"
            :loop="true"
            :animationData="
              require('@/apps/shared/assets/invoices/empty_list.json')
            "
          />
        </v-flex>
      </div>
      <div
        style="min-height: 20vh;"
        class="expande-horizontal pa-6 pt-0 centraliza"
      >
        <v-flex xs10 md6>
          <h3
            style="color: #344433;"
            class="text-center font-weight-bold fonte"
          >
            Você ainda não cadastrou nenhuma conta!
          </h3>
          <p class="pa-6 fonte text-center">
            As contas impactam diretamente no preço do seu produto, não se
            preocupe que faremos tudo automático para você ao cadastrar um novo
            produto para uma categoria, para começar a cadastrar suas contas
            basta clicar no botão abaixo ou no canto superior direito!
          </p>
          <v-btn
            class="fonte"
            :color="$theme.secondary"
            rounded
            dark
            block
            @click="create_invoice"
            >Cadastrar minha primeira conta!</v-btn
          >
        </v-flex>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import { EventBus } from "@/main.js";

export default {
  components: { LottieAnimation },
  methods: {
    create_invoice() {
      EventBus.$emit("open_create_invoice");
    }
  }
};
</script>
